@media print {
  .print-button {
    display: none;
  }
}

html,
body {
  font-size: 14px;

  --black: rgb(32, 34, 36);
  --dark-blue: rgb(13, 24, 89);
  --dark-blue-raw: 13, 24, 89;
  --purple: rgb(129, 149, 255);
  --purple-raw: 129, 149, 255;
  --gray: rgb(102, 102, 102);
  --light-gray: rgb(228, 228, 228);
  --light-gray-raw: 228, 228, 228;
  --green: rgb(6, 201, 101);
  --green-raw: 6, 201, 101;
  --dark-green: rgb(0, 182, 155);
  --red: rgb(249, 60, 101);
  --red-raw: 249, 60, 101;
  --beige: rgb(251, 245, 241);
  --beige-raw: 251, 245, 241;
  --pink: rgb(253, 198, 255);
  --orange: rgb(252, 190, 45);
  --yellow: rgb(255, 223, 0);
  --tuyo-green: rgb(96, 211, 136);
  --indigo-dark: rgb(15, 27, 87);
  --pale-white: rgb(248, 248, 251);
  --green-mint: rgb(191, 241, 216);
  --turbo: rgb(71, 86, 164);

}

.select-none {
  -webkit-user-select: none;
  user-select: none;
}

//switch
.switch-button {
  display: inline-block;
}
.switch-button .switch-button__checkbox {
  display: none;
}
.switch-button .switch-button__label {
  background-color: lightgrey;
  width: 4rem;
  height: 2rem;
  border-radius: 1rem;
  display: inline-block;
  position: relative;
}
.switch-button .switch-button__label:before {
  transition: 0.2s;
  display: block;
  position: absolute;
  width: 2rem;
  height: 2rem;
  background-color: var(--white);
  content: '';
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 1px gray;
}
.switch-button .switch-button__checkbox:checked + .switch-button__label {
  background-color: var(--tuyo-green);
}
.switch-button .switch-button__checkbox:checked + .switch-button__label:before {
  transform: translateX(2rem);
}

//custom
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.whitespace-pre-line {
  white-space: pre-line;
}

.rounded-full {
  border-radius: 100%;
}

//button
.underline-button:hover {
  text-decoration: underline;
}

//ring
.ring-green {
  box-shadow: 0 0 0 3px var(--tuyo-green);
}

.ring {
  box-shadow: 0 0 0 3px lightgray;
}

//shadow
.box-shadow-sm {
  box-shadow: 1px 1px 5px #dbdbdb;
}

.hover-darker {
  transition: 0.2s;
  &:hover {
    box-shadow: 0 0 0 3px #868585;
    filter: brightness(0.8);
  }
}

//position
.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.bottom-0 {
  bottom: 0;
}

//margin
.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mr-8 {
  margin-right: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.border-image {
  object-fit: contain;
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding: 1rem;
  aspect-ratio: 1;
  width: 30%;
}

// Font Weight
.font-weight-black {
  font-weight: 900;
}

.font-weight-extrabold {
  font-weight: 800;
}

.font-weight-bold {
  font-weight: 700;
}

.font-weight-semibold {
  font-weight: 600;
}

/* BG-... */
.bg-yellow {
  background-color: var(--yellow) !important;
}

.bg-tuyo-green {
  background-color: var(--tuyo-green) !important;
}

.bg-green-mint {
  background-color: var(--green-mint) !important;
}

.bg-purple {
  --primary-color: var(--purple-raw) !important;
  background-color: var(--purple) !important;
}

.bg-light-gray {
  --primary-color: var(--light-gray-raw) !important;
  background-color: var(--light-gray) !important;
}

.bg-amazon {
  --primary-color: #ff9900 !important;
  background-color: #ff9900 !important;
}

.bg-dark-green {
  background-color: var(--dark-green) !important;
}

.bg-orange {
  background-color: var(--orange) !important;
}

.bg-pink {
  background-color: var(--pink) !important;
}

.bg-red {
  --primary-color: var(--red-raw) !important;
  background-color: var(--red) !important;
}

.bg-beige {
  --primary-color: var(--beige-raw) !important;
  background-color: var(--beige) !important;
}

.bg-green {
  --primary-color: var(--green-raw) !important;
  background-color: var(--green) !important;
}

.bg-turbo {
  --primary-color: var(--turbo-raw) !important;
  background-color: var(--turbo) !important;
}

/* TEXT-... */
.text-dark-blue {
  color: var(--dark-blue);
}

.text-gray {
  color: var(--gray);
}

.text-light-gray {
  color: var(--light-gray);
}

.text-red {
  color: var(--red);
}

.text-green {
  --text-color: var(--green-raw);
  color: var(--green);
}

.text-purple {
  --text-color: var(--purple-raw);
  color: var(--purple);
}

.text-tuyo-green {
  --text-color: var(--tuyo-green);
  color: var(--tuyo-green);
}

.text-indigo-dark {
  --text-color: var(--indigo-dark-raw);
  color: var(--indigo-dark);
}

.text-turbo {
  --text-color: var(--turbo-raw);
  color: var(--turbo);
}

.font-style-italic {
  font-style: italic;
}

/* FONT-SIZE-... */
.font-size-125x {
  font-size: 1.25rem;
}

.font-size-150x {
  font-size: 1.5rem;
}

.font-size-175x {
  font-size: 1.75rem;
}

.font-size-2x {
  font-size: 2rem;
}

.font-size-075x {
  font-size: 0.75rem;
}

.font-size-08x {
  font-size: 0.8rem;
}

//font weight
.font-thin {
  font-weight: 100;
}
.font-extralight {
  font-weight: 200;
}
.font-light {
  font-weight: 300;
}
.font-normal {
  font-weight: 400;
}
.font-medium {
  font-weight: 500;
}
.font-semibold {
  font-weight: 600;
}
.font-bold {
  font-weight: 700;
}
.font-extrabold {
  font-weight: 800;
}
.font-black {
  font-weight: 900;
}

//font size
.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}
.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}
.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}
.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}
.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.text-5xl {
  font-size: 3rem;
  line-height: 1;
}
.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}
.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}
.text-8xl {
  font-size: 6rem;
  line-height: 1;
}
.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

//white-space
.whitespace-normal {
  white-space: normal;
}
.whitespace-nowrap {
  white-space: nowrap;
}
.whitespace-pre {
  white-space: pre;
}
.whitespace-pre-line {
  white-space: pre-line;
}
.whitespace-pre-wrap {
  white-space: pre-wrap;
}

/* TUYO COMPONENTS */
.cursor-pointer {
  cursor: pointer;
}

.tuyo-navbar {
  background-color: white;
  // box-shadow: 0 4px 4px 0 rgba(208, 208, 208, 0.25);
}

.no-shadow {
  box-shadow: none !important;
}

.tuyo-card {
  box-shadow: 0px 6px 24px 2px rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  background-color: white;
  padding: 1rem;
  transition: 500ms all ease;
}

.tuyo-dropdown {
  position: relative;
}

.tuyo-menu {
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: 60px;
  right: 0;
  width: 300px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-20px);
  z-index: 3;
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
}

.tuyo-menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.tuyo-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.tuyo-menu li {
  border-bottom: 1px solid #dddddd;
}

.tuyo-menu button {
  text-decoration: none;
  color: #333333;
  padding: 15px 20px;
  display: block;
}

a:hover {
  .tuyo-card {
    box-shadow: 0 0px 2px 0px rgba(0, 0, 0, 0.05);
  }
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.sidebar {
  max-height: 100vh;
  overflow: auto;

  .sidebar-wrapper {
    overflow-x: hidden;
  }

  & > .label {
    overflow-x: hidden;
  }

  .item {
    padding: 0.3rem 0;
    padding-left: 0.7rem;
    color: white;
    background: var(--tuyo-green);
    font-size: 1.1rem;
    * {
      display: inline-block !important;
    }

    p {
      font-weight: 800;
    }

    .item {
      opacity: 1;
      * {
        display: inline-block !important;
      }
    }

    &.parent {
      display: block;

      .label {
        * {
          display: inline-block;
        }
      }
    }

    &.active {
      &::after,
      &::before {
        content: '';
        position: absolute;
        right: 0;
        width: 3rem;
        // background: var(--tuyo-green);
        height: 0.3rem;
        z-index: 1;
      }

      &::after {
        bottom: 0rem;
        border-radius: 0 1rem 0 0;
      }

      &::before {
        top: 0rem;
        border-radius: 0 0 1rem 0;
      }
    }
    & > .label {
      padding: 0.2rem 0;
      display: flex !important;
      align-items: center;
      border-radius: 0.9rem 0 0 0.9rem;
      background: var(--tuyo-green);
    }

    &.child.active > .label {
      background: var(--white);
      width: 100%;
    }

    &.active {
      opacity: 1;
    }

    &.child.active {
      color: var(--tuyo-green);
    }

    a {
      display: flex;
    }

    &:hover {
      filter: brightness(0.9);
      z-index: 3;
    }

    &.open:hover {
      filter: brightness(1);
    }

    &.active::after,
    &.active::before {
      box-shadow: 1rem 0 0 0 var(--white);
    }

    &.parent::after {
      box-shadow: 1rem 0 0 0 var(--tuyo-green);
    }

    &.parent::before {
      box-shadow: 1rem 0 0 0 var(--tuyo-green);
    }
  }
}

.tuyo-tabs {
  display: flex;
  flex-wrap: wrap;

  &.vertical {
    height: 100%;
    flex-direction: column;
    justify-content: center;
    border-right: 2px solid var(--light-gray);
    padding-right: 1rem;

    .tab {
      width: 100%;
      font-size: 1rem;
      text-align: left;
      padding: 0.25rem 1rem;
    }
  }

  &:not(.vertical) {
    border-bottom: 2px solid var(--light-gray);
  }

  .tab {
    display: block;

    padding: 0.5rem 1rem;

    position: relative;
    font-size: 1.25rem;
    font-weight: bold;
    color: var(--gray);
    opacity: 0.7;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.active {
      color: var(--dark-blue);
      opacity: 1;
    }
  }

  .tab:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    border-bottom: 3px solid var(--purple);
    transition: 500ms all ease;
    transform: scaleX(0);
    transform-origin: 100%;
  }

  .tab.active:after {
    transform: scaleX(1);
  }
}

input.current-page {
  width: 3rem;
  margin: 0 0.5rem;
  text-align: center;
}

.tuyo-table {
  width: clamp(600px, 100%, 10000px);
  font-size: 0.875rem;

  thead {
    th {
      text-align: center;
      background-color: var(--beige);

      button {
        padding: 1rem 0.5rem;
      }

      &:first-child {
        border-bottom-left-radius: 0.5rem;
        border-top-left-radius: 0.5rem;
      }

      &:last-child {
        border-bottom-right-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 0.4px solid var(--pink);
      transition: 220ms all ease;

      &.hoverable:hover {
        box-shadow: inset 0 0 10px 0 rgba(0, 0, 0, 0.16);
      }
    }
  }
}

.opt-btn {
  transition: 220ms all ease;
  border-radius: 0.5rem;
  display: inline-flex;
  align-items: center;

  &:hover,
  &:focus {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  }

  &:focus {
    background-color: rgba(208, 208, 208, 0.25);
  }
}

.tuyo-btn {
  transition: 500ms all ease;

  &:disabled {
    background-color: var(--gray);
  }

  &:not(:disabled) {
    &:hover,
    &:focus {
      box-shadow: 0 0 0 5px rgba(var(--primary-color), 0.5);
    }
  }
}

.add-picture-btn {
  background-image: url('../icons/add-gray.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 25%;

  &.uploading {
    background: none;
  }
}

.product-picture {
  padding-top: 100%;
  border-radius: 1rem;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
  position: relative;
  transition: 220ms all ease;

  .delete {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: 220ms all ease;
  }

  &:hover {
    .delete {
      opacity: 1;
    }
  }
}

.delete {
  transition: 220ms ease;
  opacity: 0;

  &:hover {
    opacity: 1;
  }
}

.branch-office {
  border: 2px solid var(--light-gray);
  transition: 220ms;

  &:hover {
    border-color: var(--green);
  }
}

.branch-office-link {
  &:hover {
    & + button {
      opacity: 1;
    }
  }

  & + button {
    opacity: 0;
    transition: 500ms;

    &:hover {
      opacity: 1;
    }
  }
}

.product-thumbnail {
  height: 48px;
  width: 48px;
  border-radius: 0.5rem;
  background-position: center;
  background-size: cover;
}

.tuyo-login {
  height: 100vh;
  width: 100vw;

  background-repeat: no-repeat;
  background-size: 125%;
  background-position: bottom center;
}

.tuyo-modal {
  z-index: 9998;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 3rem;
  background: rgba(0, 0, 0, 0.16);
  -webkit-backdrop-filter: blur(5px);
  -moz-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: flex-start;

  .tuyo-card {
    position: sticky;
    top: 2rem;
    width: 80%;
    max-height: 100%;
  }

  .hover-scale {
    transition: 220ms all ease;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.tuyo-choice {
  position: relative;

  .selector {
    width: 100px;
    box-shadow: 0px 6px 24px 2px rgba(0, 0, 0, 0.05);
    border-radius: 1rem;
    background-color: white;
    padding: 1rem;

    position: absolute;
    left: 90%;
    top: -100%;
    z-index: 20000;
    transition: 220ms ease;
    transform-origin: center left;

    &.hidden {
      transform: scale(0, 0);
      opacity: 0;
    }
  }
}

.hidden {
  transform: scale(0, 0);
  opacity: 0;
}

.hidden-vertical {
  transform: scaleY(0);
  opacity: 0;
}

.tuyo-link {
  transition: 220ms all ease;

  &:hover {
    background-color: rgba(var(--text-color), 0.16);
  }
}

.password-input {
  display: flex;
  border: 2px solid var(--light-gray);

  input {
    border: none;
    min-width: 0;
    flex-grow: 1;
  }

  .show-hide-switch {
    display: flex;
    align-items: center;
    position: relative;

    img {
      height: 18px;
      transition: 220ms;
      position: absolute;
      left: 0;
    }
  }
}

.tuyo-toggle-buttons {
  display: flex;

  button {
    flex-grow: 1;
    text-align: center;
    transition: 220ms;
    border: 1px solid rgba(var(--dark-blue-raw), 0.16);
    background-color: white;

    &:first-child {
      border-right: none;
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }

    &:last-child {
      border-left: none;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }

    &.selected {
      font-weight: bold;
      box-shadow: inset 0 0 10px 0px rgba(var(--dark-blue-raw), 0.16);
    }

    &:not(.selected):hover {
      box-shadow: inset 0 0 3px 0 rgba(var(--dark-blue-raw), 0.16);
    }
  }
}

.tuyo-month-select {
  text-transform: capitalize;
}

.tuyo-filters {
  position: relative;

  .dialog {
    transform-origin: top center;
    transition: 220ms;
    width: 100%;
    position: absolute;
    top: 122%;
    z-index: 20001;
  }
}

.variations-table {
  width: clamp(600px, 100%, 10000px);
}

.low-stock {
  background: linear-gradient(
    to right,
    rgba(255, 0, 0, 0.36),
    rgba(255, 0, 0, 0.04)
  ) !important;
}

.marker:not(.route) {
  background-image: url('https://tuyomedia.sfo2.cdn.digitaloceanspaces.com/deli.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.marker.route {
  width: 0;
  height: 0;
}

.marker.route span {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  color: #fff;
  background: #693;
  border: solid 2px;
  border-radius: 0 70% 70%;
  box-shadow: 0 0 2px #000;
  cursor: pointer;
  transform-origin: 0 0;
  transform: rotateZ(-135deg);
}

.marker b {
  transform: rotateZ(135deg);
}

.mapboxgl-popup-content {
  font-family: 'Open Sans', sans-serif;
}

@media (max-width: 1024px) {
  .tuyo-modal {
    .tuyo-card {
      width: 100%;
    }
  }
}

@media (max-width: 768px) {
  thead,
  tbody {
    font-size: 0.85rem !important;
  }

  .variations-table {
    font-size: 0.85rem !important;
  }

  .product-picture {
    .delete {
      opacity: 1;
    }
  }

  .delete {
    opacity: 1;
  }

  .tuyo-login {
    background-size: cover;
    background-position: bottom left;
  }

  .branch-office-link {
    & + button {
      opacity: 1;
    }
  }
}

@media (max-width: 576px) {
  .tuyo-tabs {
    .tab {
      flex-grow: 1;
      text-align: center;
      font-size: 1rem;
    }
  }

  thead,
  tbody {
    font-size: 0.75rem !important;
  }

  .tuyo-modal {
    padding: 1rem;
  }
}

/* RESET */
select {
  background: white;
  border-radius: 0.5rem;
  padding: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat, repeat;
  /* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
  background-position: right 0.7em top 50%, 0 0;
  /* icon size, then gradient */
  background-size: 0.65em auto, 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

button {
  border: none;
  background-color: transparent;
  outline: none !important;
  padding: 0;
}

input,
select,
textarea {
  border: 2px solid var(--light-gray);

  &::placeholder {
    opacity: 0.25;
  }
}

label {
  margin-bottom: 0.25rem;
  color: var(--gray);
}

a {
  &:hover {
    color: unset;
  }
}
