.subcategories-table {
  width: clamp(600px, 100%, 10000px);
}

@media (max-width: 768px) {
  thead,
  tbody {
    font-size: 0.85rem !important;
  }

  .subcategories-table {
    font-size: 0.85rem !important;
  }
}