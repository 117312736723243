@media (max-width: 1024px) {
  .nav-open .sidebar {
    transform: translate3d(0px, 0, 0) !important;
  }

  .sidebar {
    position: fixed !important;
    display: block !important;
    top: 0 !important;
    height: 100% !important;
    width: 260px !important;
    right: 0 !important;
    left: auto !important;
    z-index: 1032 !important;
    visibility: visible !important;
    background-color: #999 !important;
    overflow-y: visible !important;
    border-top: none !important;
    text-align: left !important;
    padding: 0 !important;
    transform: translate3d(260px, 0, 0) !important;
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1) !important;
  }

  .main-panel {
    width: 100% !important;
  }

  .main-panel {
    transform: translate3d(0px, 0, 0) !important;
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1) !important;
    left: 0 !important;
  }

  .navbar-header .navbar-toggle {
    margin: 10px 0 10px 15px !important;
    width: 40px !important;
    height: 40px !important;
  }

  .nav-open .navbar .container {
    left: -260px;
  }

  .navbar .container {
    left: 0;
    width: 100%;
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    position: relative;
  }
}

@media (min-width: 768px) {
  .navbar-toggle {
    display: unset;
  }
}

@media (min-width: 1025px) {
  .navbar-toggle {
    display: none;
  }
}

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 230px;
  display: block;
  z-index: 1;
  color: #fff;
  font-weight: 200;
  overflow-y: scroll;
  height: 100vh;
  background-size: cover;
  background-position: center center;

  .sidebar-wrapper {
    width: 100% !important;
    position: relative;
    z-index: 4;
    padding-bottom: 30px;
  }

  .sidebar-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
  }

  .logo {
    padding: 30px 15px;
    position: relative;
    z-index: 4;

    p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      color: $white-color;
      line-height: 20px;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }

    a.logo-mini {
      float: left;
      text-align: center;
      width: 30px;
      margin-right: 15px;

      img {
        width: 40px;
        margin-left: -3px;
        display: block;
        margin-top: 2px;
      }
    }

    a.logo-normal {
      display: block;
    }

    .logo-img {
      width: 100%;
      display: inline-block;
      border-radius: 30px;
      text-align: center;

      img {
        width: 100%;
      }
    }
  }

  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  .nav {
    li {
      margin-left: 1rem;
      width: 100%;
      border-top-left-radius: 1.5rem;
      border-bottom-left-radius: 1.5rem;

      > a {
        color: #666;
        margin: 5px 0;
        opacity: 0.5;
        padding-left: 0;
        margin: 0;
      }

      &:hover > a,
      &.open > a,
      &.open > a:focus,
      &.open > a:hover {
        background: rgba(255, 255, 255, 0.13);
        opacity: 1;
      }

      &.active > a {
        opacity: 1;
        background: rgba(255, 255, 255, 0.23);
      }

      &.active {
        background-color: rgba(var(--green-raw), 0.25);
      }
    }

    p {
      margin: 0;
      line-height: 30px;
      font-weight: 600;
    }

    .caret {
      top: 24px;
      position: absolute;
      right: 15px;
    }

    i {
      font-size: 28px;
      float: left;
      margin-right: 15px;
      line-height: 30px;
      width: 30px;
      text-align: center;
    }
  }
}

.sidebar,
body > .navbar-collapse {
  .logo {
    padding: 30px 20px;

    p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      color: $white-color;
      line-height: 20px;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }

    .simple-text {
      text-transform: uppercase;
      padding: $padding-small-vertical $padding-zero;
      display: block;
      font-size: $font-size-large;
      color: $white-color;
      font-weight: $font-weight-normal;
      line-height: 30px;
    }
  }

  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  &:after,
  &:before {
    display: block;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  &:before {
    opacity: 0.33;
    // background: #000000;
  }

  &:after {
    background-color: var(--tuyo-green);
    border-right: 1px solid white;
    z-index: 3;
    opacity: 1;
  }

  &[data-image]:after,
  &.has-image:after {
    opacity: 0.77;
  }

  &[data-color='jappi']:after {
    @include icon-gradient(#162642, #162642);
  }

  &[data-color='blue']:after {
    @include icon-gradient($new-dark-blue, $blue-color-bottom);
  }

  &[data-color='azure']:after {
    @include icon-gradient($new-blue, $azure-color-bottom);
  }

  &[data-color='green']:after {
    @include icon-gradient($new-green, $green-color-bottom);
  }

  &[data-color='orange']:after {
    @include icon-gradient($new-orange, $orange-color-bottom);
  }

  &[data-color='red']:after {
    @include icon-gradient($new-red, $red-color-bottom);
  }

  &[data-color='purple']:after {
    @include icon-gradient($new-purple, $purple-color-bottom);
  }
}

.main-panel {
  background-color: white;
  position: relative;
  float: right;
  width: $sidebar-width;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  > .content {
    padding: 30px 15px;
    min-height: 100%;

    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  > .footer {
    border-top: 1px solid #e7e7e7;
  }

  .navbar {
    margin-bottom: 0;
  }
}

.sidebar,
.main-panel {
  -webkit-transition-property: top, bottom;
  transition-property: top, bottom;
  -webkit-transition-duration: 0.2s, 0.2s;
  transition-duration: 0.2s, 0.2s;
  -webkit-transition-timing-function: linear, linear;
  transition-timing-function: linear, linear;
  -webkit-overflow-scrolling: touch;
}
